<template>
    <div>
        <ValidationObserver ref="form">
            <form @submit.prevent="submit" @change="triggerProfileInfoFormChange">
                <div class="client-demo-card-header">
                    <h3>Member Theme and Profile</h3>
                </div>
                <div class="client-demo client-profile-form general px-4">
                    <div class="flex colx4 wrap">
                        <Input label="Theme" name="Client Theme" type="text" id="clientDemographicsChosenAgeGroup"
                            v-model="clientProfileInfo.ageGroup" :disabled="true" />
                        <Dropdown name="Client profile" id="clientDemographicsChosenTheme" label="Chosen Profile"
                             :options="companyProfileOptions" :disabled="false"
                            v-model="clientProfileInfo.clientProfile" :placeholder="`Select member profile`"
                            @dropdownInput="dropdownChangeHandler" />

                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { clients } from '@/util/apiRequests';
import Input from '@/components/general/validatedInputs/Input.vue';
import Dropdown from '../general/validatedInputs/DropdownWithSearchInput';
import { memberProfile } from '@/util/apiRequests';
export default {
    name: 'ClientDemographicsProfile',
    components: { Dropdown, ValidationObserver, Input },
    props: {
        clientId: {
            type: [Number, String],
            default: 0
        },
        newLicense: {
            default: null
        }
    },
    data() {
        return {
            isProfileInfoFormChanged: false,
            companyProfileOptions: [],
            clientProfileInfo: {
                clientProfileName: null,
                clientProfile: null,
                ageGroup: null
            },

        };
    },
    async created() {
        await this.fetchCompanyProfiles()
        await this.getAndSetClientProfileInfo()

    },
    methods: {
        async getAndSetClientProfileInfo() {
            try {
                let res = await this.$api.get(clients.getClientProfileInfo(this.clientId))
                if (res.status === 200) {
                    const clientProfileDetails = this.companyProfileOptions.find(profile => profile.id === res.data[0].clientProfile);
                    if( clientProfileDetails ) {
                        const { id: clientProfile, theme: ageGroup, value: clientProfileName } = clientProfileDetails;
                        if (clientProfile && clientProfileName && ageGroup)
                            this.clientProfileInfo = { clientProfileName, clientProfile, ageGroup }
                    }
                }
                else {
                    this.$toasted.error('Failed to fetch member theme and profile info')
                }
            }
            catch (err) {
                console.error(err)
                this.$toasted.error('Failed to fetch member profile and theme info')
            }

        },

        dropdownChangeHandler({ id, value, theme }) {
            this.clientProfileInfo.clientProfileName = value
            this.clientProfileInfo.clientProfile = id
            this.clientProfileInfo.ageGroup = theme
            this.triggerProfileInfoFormChange()
        },
        triggerProfileInfoFormChange() {
            this.isProfileInfoFormChanged = true;
        },
        async fetchCompanyProfiles() {
            try {
                let result = await this.$api.get(memberProfile.getMemberProfileList());
                if (result?.data?.data) {
                    this.companyProfileOptions = result.data.data.map(item => {
                        return {
                            id: item.id,
                            disabled: false,
                            value: item.profile_name,
                            theme: item.age_group
                        }
                    })
                }
            }
            catch (err) {
                console.log("err", err)
            }
        },

        async submit() {
            const { clientProfile, ageGroup } = this.clientProfileInfo
            let result = null
            if (clientProfile && ageGroup)
                result = await this.hitPutEndpointWithData(clients.putClientProfileInfo(this.clientId), { clientProfile, ageGroup });
        },
        async hitPutEndpointWithData(endpoint, data) {
            const form = this.$refs.form;
            try {
                const res = await this.$api.put(endpoint, data);

                if (res.status === 400) {
                    form.setErrors(res.data);
                }
                if (res.status === 409) {
                    this.$toasted.error('Unable to save info, email is already in use.');
                }
                else {
                    if (this.$route.params?.client_id == 'new') {
                        this.assignId(res.data[0]);
                    }
                }

                return res.data;
            } catch (err) {
                this.$toasted.error('Could not save data.');
                return null;
            }
        }
    },

    watch: {
        clientProfileInfo: {
            handler() {
                this.$emit('clientProfileInfoChange', { context: this });
            },
            deep: true
        }
    }
};
</script>
